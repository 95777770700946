
<template>
    <div>
        <b-card body-class="pb-50">
    <!-- <h6>Product Trends by Month</h6> -->
    <vue-apex-charts
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import useLineSeriesStoreModule from '@/views/dashboard/ecommerce/useLineSeriesStoreModule'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import VueApexCharts from 'vue-apexcharts';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker,VueApexCharts,
    },
    directives: {},
    setup(props, context) {
        const saleTrends = ref([])
        const saleTrendsAct = ref([])
        const saleTrendsEst = ref([])
        const saleTrendsName = ref([])

        const CROMIS_STORE_MODULE_NAME = 'cromis-sale_trend'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, useLineSeriesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        })

        onMounted(async () => {

            await store.dispatch('cromis-sale_trend/list')
                .then(response => {
                    saleTrends.value = response.data
                    console.log(saleTrends.value.sales_trend)
                    saleTrends.value.sales_trend.forEach(trend => {
                        saleTrendsAct.value.push(parseFloat(trend.actual_kgs));
                        saleTrendsEst.value.push(parseFloat(trend.est_kgs));
                        saleTrendsName.value.push(trend.location);
                    });

                    // console.log(saleTrends.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })


        return {
            saleTrendsName: console.log(saleTrendsName.value),
            
            series: [
        {
          name: 'Actual Weight Trends',
          data: saleTrendsAct.value
        },
        // {
        //   name: 'Estimated Weight Trends',
        //   data: saleTrendsEst.value
        // }
      ],
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
            
          categories: saleTrendsName.value,
        },
        yaxis: {
          title: {
            text: 'Count'
          }
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        colors: ['#7367F0', '#00CFE8'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top'
        }
      },
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>