import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                // params = "location_id=";
                
                if(params != null){
                    axios.get('dashboard/sales_trend?location_id='+params.location_id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
                }else{
                    axios.get('dashboard/sales_trend')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
                }
                // axios.get('dashboard/sales_trend?'+params+ 60)
               
            })
        },
        show: (cntx, { id }) => {
            id = 60;
            axios.get(`dashboard/sales_trend${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        landing: (cntx) => {
            axios.get(`dashboard/sales_trend`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        dailData: (cntx, params) => {
            console.log(params)
            let data = {}
            if(params != null ){
                data = {
                    "location_id": params.location_id,
                    "level": params.level
                  }
            }else{
                data = {
                    "level": 0
                  }
            }
           
              console.log(data)
            return new Promise((resolve, reject) => {
                axios.post('dashboard/daily_purchases', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
    }
}
