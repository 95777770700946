<template>
  <div>
    <b-card body-class="pb-50">
      <b-breadcrumb>
        <b-breadcrumb-item
          v-for="(breadcrumb, index) in locationBreadcrumbs"
          :key="index"
          :active="index === locationBreadcrumbs.length - 1"
        >
          <a
            v-if="index !== locationBreadcrumbs.length - 1"
            href="#"
            @click.prevent="onBreadcrumbClick(breadcrumb.id, index)"
          >
            {{ breadcrumb.text }}
          </a>
          <span v-else>{{ breadcrumb.text }}</span>
        </b-breadcrumb-item>
      </b-breadcrumb>
      <b-col cols="12">
        <b-form-group label-for="Location" label="Location">
          <b-form-select size="md" v-model="selectedLocationId" @change="onChange($event)">
            <b-form-select-option :value="null">-- Select --</b-form-select-option>
            <b-form-select-option v-for="location in locations" :key="location.id" :value="location.id">
              {{ location.location.toUpperCase() }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <vue-apex-charts :key="chartKey" height="350" :options="chartOptions" :series="series" />
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
  BForm, BFormGroup,
  BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
  BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker, BBreadcrumb, BBreadcrumbItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api'
import useLineSeriesStoreModule from '@/views/dashboard/ecommerce/useLineSeriesStoreModule'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import VueApexCharts from 'vue-apexcharts';
import store from '@/store'
import MultiSelect from 'primevue/multiselect';

export default {
  components: {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
    BSpinner, BBreadcrumb, BBreadcrumbItem,
    ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker, VueApexCharts, MultiSelect
  },
  setup() {
    const saleTrends = ref([])
    const saleTrendsAct = ref([])
    const saleTrendsEst = ref([])
    const saleTrendsName = ref([])
    const locations = ref([])
    const selectedLocationId = ref(null);
    const selectedLocationName = ref(null);
    const locationBreadcrumbs = ref([]);
    const CROMIS_STORE_MODULE_NAME = 'cromis-sale_trend'
    if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, useLineSeriesStoreModule)

    onUnmounted(() => {
      store.unregisterModule(CROMIS_STORE_MODULE_NAME)
    })

    const chartKey = ref(Date.now())

    onMounted(async () => {
      await store.dispatch('cromis-sale_trend/list')
        .then(response => {
          locations.value = response.data.sales_trend
          saleTrends.value = response.data
          saleTrends.value.sales_trend.forEach(trend => {
            saleTrendsAct.value.push(parseFloat(trend.actual_kgs));
            saleTrendsEst.value.push(parseFloat(trend.est_kgs));
            saleTrendsName.value.push(trend.location);
          });
          chartKey.value = Date.now()
        })
        .catch(error => {
          console.log('got error', error)
        })
    })

    const onChange = async (event) => {
      const location = locations.value.find(loc => loc.id === event);
      selectedLocationName.value = location ? location.location : null;
      if (location) {
        locationBreadcrumbs.value.push({ text: location.location, id: location.id });
      }
      let payload = {
        'location_id': event
      }
      await store.dispatch('cromis-sale_trend/list', payload)
        .then(response => {
          saleTrendsAct.value = []
          saleTrendsEst.value = []
          saleTrendsName.value = []
          locations.value = response.data.sales_trend
          response.data.sales_trend.forEach(trend => {
            saleTrendsAct.value.push(parseFloat(trend.actual_kgs));
            saleTrendsEst.value.push(parseFloat(trend.est_kgs));
            saleTrendsName.value.push(trend.location);
          });
        })
        .catch(error => {
          console.log('got error', error)
        })
    }

    const onBreadcrumbClick = async (id, index) => {
      // Remove breadcrumbs after the clicked one
      locationBreadcrumbs.value = locationBreadcrumbs.value.slice(0, index + 1);
      selectedLocationId.value = id;
      await onChange(id);
    };

    const series = ref([
      {
        name: 'Purchase',
        data: saleTrendsAct.value
      },
    ])

    const chartOptions = ref({
      chart: {
        type: 'bar',
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          // endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true, // Enable data labels
        formatter: function (val) {
          return val; // Add the unit to the label
        },
        style: {
          fontSize: '12px',
          colors: ["#FFFFFF"] // Set color to white
        },
        background: {
    enabled: true,
    foreColor: '#fff',
    padding: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#fff',
    opacity: 0.9,
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45
    }
  },

  dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45
  },
        offsetY: -10 // Adjust this value to position the data labels above the bars
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: saleTrendsName.value,
      },
      yaxis: {
        title: {
          text: 'Weight (Kgs)',
          style: {
            color: '#FFFFFF' // Set color of the y-axis title to white
          }
        },
        labels: {
          style: {
            colors: '#FFFFFF' // Set color of the y-axis labels to white
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " kgs"
          }
        }
      },
      colors: ['#7367F0', '#00CFE8'],
      title: {
        text: 'Tobacco Purchase Trends by Location',
        align: 'left',
        style: {
          color: '#FFFFFF' // Set color of the title to white
        }
      },
      legend: {
        position: 'top',
        labels: {
          colors: '#FFFFFF' // Set color of the legend labels to white
        }
      },
      grid: {
        show: true,
        borderColor: '#FFFFFF', // Set color of the grid lines to white
      },
    })

    watch([saleTrendsAct, saleTrendsName], () => {
      series.value[0].data = saleTrendsAct.value
      chartOptions.value.xaxis.categories = saleTrendsName.value
      chartKey.value = Date.now()
    })

    return {
      series,
      chartOptions,
      chartKey,
      selectedLocationId,
      onChange,
      locations,
      selectedLocationName,
      locationBreadcrumbs,
      onBreadcrumbClick
    }
  }
}
</script>
